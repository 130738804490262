.header-logo {
  display: inline-block;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.header-logo::before {
  content: '';
  width: 80px;
  height: 80px;
  background: url(../../assets/logo.png);
  background-size: contain;
  transition: opacity 1.5s;
  position: absolute;
  opacity: 1;
  left: 0;
  top: 0;
}
.header-logo:after {
  content: '';
  width: 75px;
  height: 75px;
  background: url(../../assets/logoHover.png);
  background-size: contain;
  transition: opacity 1.5s;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}
.header-logo:hover:after {
  opacity: 1;
}
.header-logo:hover:before {
  position: absolute;
  opacity: 0;
}
.header-menu {
  display: flex;
  justify-content: space-between;
  width: 60%;
  padding: 30px;
}
.header-burger-button {
  display: none;
  font-size: 30px;
  z-index: 100;
}
.header-openMenu {
  padding-top: 630px !important;

  transition: padding-top 1s !important;
}

@media screen and (max-width: 1265px) {
  .header-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: -550px;
    left: 0px;
    width: 100%;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 50px;
    background: white;
    border: 1px solid #0d7834;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: padding-top 2s;
  }
  .header-burger-button {
    display: block;
    z-index: 100;
  }
  .header-logo {
    width: 60px;
    height: 60px;
    margin-left: 5.5%;
  }
  .header-logo::after {
    width: 60px;
    height: 60px;
  }
  .header-logo::before {
    width: 60px;
    height: 60px;
  }
}
