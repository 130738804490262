.visitCard_contact_modal-box {
  background-image: url('../../assets/ContactModalBg.png');
  background-size: cover;
  outline: 0;
}
.visitCard_contact_modal-close {
  transition: all 1.5s;
}
.visitCard_contact_modal-close:hover {
  transform: rotate(360deg);
}
.contactModal-title-text {
  margin-bottom: 10px;
  line-height: 22px;
  text-align: center;
}
.contactModal-main-text {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-size: 26px;
  line-height: 39px;
  text-align: left;
  color: black;
}
.contactModal-input-text {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
}
.contactModal-inputs-first {
  margin-bottom: 10px;
  margin-top: 80px;
}
@media screen and (max-width: 600px) {
  .visitCard_contact_modal-box {
    max-height: 100%;
    padding: 20px;
  }
  .contactModal-main-text {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 10px !important;
  }
  .contactModal-input-text {
    font-size: 22px;
    line-height: 24px;
  }
  .contactModal-inputs-first {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 450px) {
  .contactModal-title-text {
    font-size: 20px;
    margin-bottom: 5px;
    line-height: 20px;
  }
  .contactModal-main-text {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 10px !important;
  }
  .contactModal-input-text {
    font-size: 20px;
    line-height: 22px;
  }
  .contactModal-inputs-first {
    margin-bottom: 10px;
    /* margin-top: 0px; */
  }
}
