.layout-root {
  background-image: url(../assets/bgTop.png);

  background-repeat: no-repeat;
  background-position: right 8% top 2%;
  background-size: 522px;
}

@media screen and (max-width: 1250px) {
  .layout-root {
    background-repeat: no-repeat;
    background-size: 450px 390px;
    background-position-y: 100px;
    background-position-x: 90%;
  }
  .layout-root-cover {
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 1024px) {
  .layout-root {
    background-repeat: no-repeat;
    background-size: 325px 290px;
    background-position-y: 100px;
    background-position-x: 90%;
  }
  .layout-root-cover {
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 920px) {
  .layout-root {
    background-repeat: no-repeat;
    background-size: 425px 390px;
    background-position-y: 100px;
    background-position-x: 50%;
  }
  .layout-root-cover {
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 600px) {
  .layout-root {
    background-repeat: no-repeat;
    background-size: 325px 290px;
    background-position-y: 100px;
    background-position-x: 50%;
  }
  .layout-root-cover {
    background-repeat: no-repeat;
  }
}
