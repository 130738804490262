.react-switch-checkbox {
  height: 0;
  width: 0;
  position: relative;
  visibility: hidden;
}

.react-switch-label {
  font-family: 'Noto Sans';
  cursor: pointer;
  width: 100px;
  height: 40px;
  background-color: #fff;
  border-radius: 32px;
  border: 1px solid black;
  position: relative;
  transition: background-color 0.2s;
  transition: all 0.5s;
}
.react-switch-label:hover {
  box-shadow: 3px 3px 3px rgb(42 124 85 / 30%);
}
.react-switch-labels span:hover {
  color: rgb(42 124 85 / 90%);
  /* text-shadow: 5px 5px 1px rgb(42 124 85 / 30%); */
}

.react-switch-switcher {
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px;
  width: 57px;
  height: 39px;
  transition: 0.2s;
  background: black;
  border-radius: 32px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-switch-switcher:after {
  font-family: 'Noto Sans';
  color: #ffffff;
  content: 'UA';
  z-index: 0;
  font: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.2px;
}

.react-switch-checkbox:checked + .react-switch-switcher:after {
  content: 'EN';
}

.react-switch-checkbox:checked + .react-switch-switcher {
  left: 101%;
  transform: translateX(-100%);
}

.react-switch-checkbox:active + .react-switch-switcher {
  width: 60px;
}

.react-switch-labels {
  z-index: 0;
  height: 100%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  position: absolute;
  width: 100%;
  top: 0;
}

.react-switch-labels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  color: black;
  transition: all 0.5s;
}
.react-switch-labels span:first-child {
  left: 0;
}
.react-switch-labels span:last-child {
  right: 0;
}

@media screen and (max-width: 600px) {
  .react-switch-label {
    width: 70px;
    height: 30px;
  }

  .react-switch-switcher {
    top: -1px;
    left: 0px;
    width: 38px;
    height: 30px;
  }

  .react-switch-switcher:after {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
  }

  .react-switch-checkbox:checked + .react-switch-switcher:after {
    content: 'EN';
  }

  .react-switch-checkbox:checked + .react-switch-switcher {
    left: 100%;
    transform: translateX(-100%);
  }

  .react-switch-checkbox:active + .react-switch-switcher {
    width: 45px;
  }

  .react-switch-labels {
    font-size: 14px;
    line-height: 20px;
  }

  .react-switch-labels span {
    width: 50%;
    height: 100%;
  }
}
