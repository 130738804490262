.mainPage-animationBlock-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  max-width: 1340px !important;
  width: 100%;
}
.animationBlock-wrapper {
  position: relative !important;
  min-height: 500px;
  width: 100%;
}
.animationBlock-unactive0 {
  position: absolute !important;
  bottom: 50%;
  left: 0%;
}
.animationBlock-active0 {
  position: absolute !important;
  bottom: 50%;
  left: 0%;
}
.animationBlock-unactive1 {
  position: absolute !important;
  bottom: 17%;
  left: 2.6%;
}
.animationBlock-active1 {
  position: absolute !important;
  bottom: 17%;
  left: 2.6%;
}
.animationBlock-unactive2 {
  position: absolute !important;
  bottom: 24.2%;
  left: -4%;
  z-index: 10;
}
.animationBlock-active2 {
  position: absolute !important;
  bottom: 24.2%;
  left: -4%;
  z-index: 10;
}
.animationBlock-unactive3 {
  position: absolute !important;
  bottom: 3%;
  left: 16.9%;
}
.animationBlock-active3 {
  position: absolute !important;
  bottom: 3%;
  left: 16.9%;
}
.animationBlock-unactive4 {
  position: absolute !important;
  bottom: 51.5%;
  left: 19.3%;
  z-index: 11;
}
.animationBlock-active4 {
  position: absolute !important;
  bottom: 51.5%;
  left: 19.3%;
  z-index: 11;
}
.animationBlock-unactive5 {
  position: absolute !important;
  bottom: 74.7%;
  left: 13.4%;
  z-index: 10;
}
.animationBlock-active5 {
  position: absolute !important;
  bottom: 74.7%;
  left: 13.4%;
  z-index: 10;
}
.animationBlock-unactive6 {
  position: absolute !important;
  bottom: 44%;
  left: 33.7%;
  z-index: 10;
}
.animationBlock-active6 {
  position: absolute !important;
  bottom: 44%;
  left: 33.7%;
  z-index: 10;
}
.animationBlock-unactive7 {
  position: absolute !important;

  bottom: 51%;
  left: 17.8%;
  z-index: 10;
}
.animationBlock-active7 {
  position: absolute !important;

  bottom: 51%;
  left: 17.8%;
  z-index: 10;
}

.slideStyle {
  border: 1px solid #0d7834;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 9px rgb(42 124 85 / 30%) !important;
  height: 230px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative !important;
}
.slideStyleDevops {
  width: 100%;
  /* height: 200px !important; */
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
  position: relative !important;
}
.slick-slide {
  padding: 8px;
  /* border: 1px solid #0d7834; */
  /* width: 300px !important;
  height: 300px !important; */
}

.slick-prev {
  left: -525px !important;
}
.slick-next {
  left: -390px !important;
}
.slick-prev,
.slick-next {
  width: 60px !important;
  height: 60px !important;
  border: 1px solid #0d7834 !important;

  background-color: white !important;
  border-radius: 50px !important;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 75% !important;
  display: block;
}
.slick-next:hover {
  box-shadow: 12px 9px 41px rgb(42 124 85 / 30%) !important;
}
.slick-prev:hover {
  box-shadow: 12px 9px 41px rgb(42 124 85 / 30%) !important;
}
.slick-prev:before {
  content: '' !important;
  background-image: url('../../assets/VectorPrev.png') !important;
  background-size: 15px 30px;
  width: 15px;
  height: 30px;
  display: inline-block;
}
.slick-next:before {
  content: '' !important;
  background-image: url('../../assets/VectorNext.png') !important;
  background-size: 15px 30px;
  width: 15px;
  height: 30px;
  display: inline-block;
}

.mainPage-title-container {
  display: flex !important;
  flex-direction: column;
  align-items: left;
  margin-top: 15%;
  margin-bottom: 200px;
  margin-left: 0px !important;
  margin-right: 40% !important;
  max-width: 60% !important;
}
.mainPage-title {
  font-family: 'Noto Sans';
  font-style: normal;
  text-align: left;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;

  margin-bottom: 29px;
  color: black;
}
.mainPage-subtitle {
  font-family: 'Noto Sans';
  font-style: normal;
  text-align: left;
  font-weight: 400;
  font-size: 24px;

  margin-bottom: 71px;
  color: black;
}
.mainPage-expertice-paper-container {
  margin-bottom: 100px;
  margin-left: 0;
  width: 100%;
  height: 370px;
}
.mainPage-expertice-paper {
  box-shadow: 12px 9px 41px rgba(42, 124, 85, 0.3);
  border: 1px solid #0d7834;
  border-radius: 50px !important;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}
.mainPage-expertice-item {
  direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.mainPage-expertice-item-popover {
  box-shadow: 12px 9px 41px rgb(42 124 85 / 30%);
  border: 1px solid #0d7834;
  position: absolute;
  top: 60%;

  width: fit-content;
  max-width: 400px;
  padding: 10px 30px 20px 30px;
  z-index: 1000;
}
.mainPage-expertice-papers-title {
  margin-top: 16px;
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  color: black;
  transition: all 0.5s;
  text-decoration: underline;
  cursor: pointer;
}
.mainPage-expertice-papers-title:hover {
  color: rgb(42 124 85 / 90%);
}
.mainPage-about-text {
  font-family: 'Noto Sans';
  text-align: left;
  color: black;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  max-width: 100%;
}
.mainPage-about-bg1-text-wrapper {
  max-width: 60%;
}
.mainPage-about-bg1 {
  background-image: url(../../assets/WhoWeAre/bg1.png);
  box-shadow: 12px 9px 41px rgb(42 124 85 / 30%);
  border: 1px solid #0d7834;
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 15px;
  padding: 20px 89px 20px 20px;
  max-width: 77%;
  position: relative;
  left: -80px;
  display: flex;
  justify-content: flex-end;
  min-height: 375px;
  align-items: center;
}
.mainPage-about-logo {
  margin: 10px 45% 10px auto;
  display: block;
  width: 110px;
}
.mainPage-about-bg2-wrapper {
  display: flex;
  margin-bottom: 135px;
}
.mainPage-about-bg2 {
  background-image: url(../../assets/WhoWeAre/bg2.png);
  box-shadow: 12px 9px 41px rgb(42 124 85 / 30%);
  border: 1px solid #0d7834;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;
  border-radius: 15px;
  padding: 30px 30px 30px 89px;
  max-width: 77%;
  position: relative;
  right: -80px;
  min-height: 365px;
  display: flex;
  align-items: center;
}
.mainPage-about-vector {
  margin: 10px auto 10px 34%;
  display: block;
  width: 140px;
}
.mainPage-whyUs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  max-width: 1340px !important;
  padding-left: 20px;
  padding-right: 20px;
}

.mainPage-whyUs-text {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
  color: black;
}
.mainPage-whyUs-papers {
  box-shadow: 12px 9px 41px rgba(42, 124, 85, 0.18);
  border: 1px solid #0d7834;
  border-radius: 40px !important;
  height: 397px;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 20px 60px 0px;
  margin: 20px;
}
.mainPage-whyUs-papers-title {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: left;
  text-transform: uppercase;
  color: black;
  margin-top: 0px !important;
}
.mainPage-whyUs-papers-text {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: black;
}

.MuiAccordion-root {
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: transparent;
}
.MuiAccordion-root::before {
  content: none;
}
.MuiAccordionSummary-root {
  border: 2px solid transparent !important;
  box-shadow: 0px 1px 10px rgb(42 124 85 / 30%);
  border-radius: 50px;
  background: #eaf0ec;
}
.MuiAccordion-root:first-of-type {
  border: 2px solid transparent !important;
  background-color: transparent;
}
.MuiAccordion-root:last-of-type {
  border: 2px solid transparent !important;
  background-color: transparent;
}
.mainPage-FAQ-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 0px 20px;
  max-width: 1340px !important;
}
.mainPage-FAQ-container-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mainPage-FAQ-text {
  font-family: 'Noto Sans';

  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: left;
  color: black;
  margin-bottom: 50px;
}

.mainPage-FAQ-text-exact {
  font-family: 'Noto Sans';
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: left;
  color: black;
  transition: all 0.5s;
}
/* .mainPage-FAQ-accordion:hover .mainPage-FAQ-text-exact {
  color: rgb(42 124 85 / 90%);
}
.mainPage-FAQ-text-exact:hover {
  color: rgb(42 124 85 / 90%);
} */
.mainPage-FAQ-answer {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  color: black;
}
.mainPage-FAQ-accordion {
  padding: 10px 10px;
}
.mainPage-FAQ-accordion-wrapper::-webkit-scrollbar {
  display: none;
}
.mainPage-FAQ-accordion-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  max-height: 495px;
  position: relative;
}
.mainPage-FAQ-overflow-marker {
  display: flex;
  max-width: 150px;
  align-items: center;
  z-index: 1000;
}
.mainPage-FAQ-overflow-marker-2 {
  display: flex;
  max-width: 150px;
  align-items: center;
  z-index: 000;
}
.mainPage-CoOperation-container {
  width: 100% !important;
}
.mainPage-whyUs-tabPanel {
  border: 1px solid #0d7834;

  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: white;
  padding: 50px 82px;
}
.Mui-selected {
  /* border: 1px solid #0d7834 !important; */
  /* box-shadow: 0px 0px 8px rgb(42 124 85 / 30%); */
  background-color: transparent !important;
  border-bottom: 0 !important;
  border-radius: 15px 15px 0 0 !important;
  position: relative !important;
}

.MuiTabs-root {
  position: absolute !important;
  overflow: scroll;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */

  top: -105px;
  width: 100%;
}

.MuiTabs-flexContainer {
  padding: 10px 10px 0px 10px;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 0 auto;
  max-width: 1310px;
  justify-content: space-between;
}
.MuiTabs-flexContainer::-webkit-scrollbar {
  background-color: transparent;
  cursor: pointer !important;

  height: 6px;
}

/* scrollbar itself */
.MuiTabs-flexContainer::-webkit-scrollbar-thumb {
  background-color: #0d7834;
  border-radius: 16px;
  cursor: pointer !important;
}
.MuiTabs-flexContainer::-webkit-scrollbar-thumb:hover {
  background-color: #208043;
  cursor: pointer !important;
  /* border: 4px solid #f4f4f4; */
}

/* set button(top and bottom of the scrollbar) */
.MuiTabs-flexContainer::-webkit-scrollbar-button {
  display: none;
}
.MuiTabs-indicator {
  display: none;
}
.MuiBox-root {
  border: none;
}
@media screen and (max-width: 1390px) {
  .mainPage-title-container {
    padding-right: 50px !important;
  }
}
@media screen and (max-width: 1250px) {
  .mainPage-title-container {
    padding-right: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .animationBlock-unactive0 {
    bottom: 50%;
    left: -9%;
  }
  .animationBlock-active0 {
    bottom: 50%;
    left: -9%;
  }
  .animationBlock-unactive1 {
    bottom: 17%;
    left: -5.2%;
  }
  .animationBlock-active1 {
    bottom: 17%;
    left: -5.2%;
  }
  .animationBlock-unactive2 {
    bottom: 24.2%;
    left: -13.8%;
    z-index: 10;
  }
  .animationBlock-active2 {
    bottom: 24.2%;
    left: -13.8%;
    z-index: 10;
  }
  .animationBlock-unactive3 {
    bottom: 3%;
    left: 13.2%;
  }
  .animationBlock-active3 {
    bottom: 3%;
    left: 13.2%;
  }
  .animationBlock-unactive4 {
    bottom: 51.5%;
    left: 16.3%;
    z-index: 11;
  }
  .animationBlock-active4 {
    bottom: 51.5%;
    left: 16.3%;
    z-index: 11;
  }
  .animationBlock-unactive5 {
    bottom: 74%;
    left: 8.7%;
    z-index: 10;
  }
  .animationBlock-active5 {
    bottom: 74%;
    left: 8.7%;
    z-index: 10;
  }
  .animationBlock-unactive6 {
    bottom: 43.8%;
    left: 35.3%;
    z-index: 10;
  }
  .animationBlock-active6 {
    bottom: 43.8%;
    left: 35.3%;
    z-index: 10;
  }
  .animationBlock-unactive7 {
    bottom: 51%;
    left: 14.4%;
    z-index: 10;
  }
  .animationBlock-active7 {
    bottom: 51%;
    left: 14.4%;
    z-index: 10;
  }

  .mainPage-whyUs-tabPanel {
    padding: 50px 50px;
  }
  .MuiTabs-flexContainer {
    margin-bottom: 20px;
  }
  .mainPage-title-container {
    align-items: left;
    margin-bottom: 60px;
    margin-left: 0px !important;
    margin-right: 0% !important;
    max-width: 60% !important;
    margin-top: 200px;
  }
  .mainPage-title {
    font-size: 40px;
    margin-bottom: 50px;
  }
  .mainPage-expertice-paper-container {
    margin-bottom: 55px;
  }
  .mainPage-expertice-paper {
    padding: 30px 0px;
  }
  .mainPage-expertice-papers-title {
    max-width: 350px;
    font-size: 24px;
    line-height: 39px;
    text-align: center;
    color: black;
    cursor: pointer;
  }
  .mainPage-expertice-item {
    flex-direction: column;
    margin-bottom: 10px;
  }
  /* .mainPage-expertice-item-popover {
    top: 90%;
  } */
  .mainPage-about-bg1 {
    box-shadow: 12px 9px 41px rgba(42, 124, 85, 0.3);
    border: 1px solid #0d7834;
    padding: 20px;
    max-width: 90%;
    left: -50px;
    background-position-x: -80px;
  }
  .mainPage-about-logo {
    width: 90px !important;
  }
  .mainPage-about-bg1-text-wrapper {
    max-width: 60%;
  }
  .mainPage-about-text {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
  }

  .mainPage-about-bg2 {
    box-shadow: 12px 9px 41px rgba(42, 124, 85, 0.3);
    border: 1px solid #0d7834;
    background-position-x: right;
    background-position-y: -25px;
    right: -50px;
    padding: 20px;
    padding-left: 20px;
    max-width: 90%;
  }
  .mainPage-whyUs-text {
    font-size: 20px;
  }
  .mainPage-whyUs-papers-title {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
  .mainPage-whyUs-papers-text {
    font-size: 22px;
    line-height: 23px;
  }
  .mainPage-whyUs-papers {
    padding: 20px 20px 0px;
  }
  .mainPage-FAQ-text-exact {
    font-size: 20px;
    line-height: 22px;
  }
  .mainPage-FAQ-answer {
    font-size: 16px;
    line-height: 23px;
  }
  .mainPage-FAQ-accordion {
    padding: 5px 10px;
  }
}
@media screen and (max-width: 920px) {
  .mainPage-FAQ-text {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 30px;
  }
  .mainPage-whyUs-tabPanel {
    padding: 30px 30px;
  }
  .mainPage-title-container {
    align-items: left;
    margin-bottom: 60px;
    margin-left: 0px !important;
    margin-right: 0% !important;
    max-width: 100% !important;
    margin-top: 500px;
  }
  .mainPage-subtitle {
    font-family: Noto Sans;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    font-size: 24px;

    margin-bottom: 71px;
    color: black;
  }
  .slick-prev {
    left: -10px !important;
    top: 120px !important;
    z-index: 99;
  }
  .slick-next {
    left: 93.5% !important;
    top: 120px !important;
    z-index: 99;
  }
  .mainPage-expertice-item-popover {
    top: unset !important;
    left: 5% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 600px) {
  .mainPage-subtitle {
    font-family: Noto Sans;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    font-size: 24px;

    margin-bottom: 71px;
    color: black;
  }
  .mainPage-title-container {
    align-items: center;
    margin-bottom: 60px;
    margin-left: 0px !important;
    margin-right: 0% !important;
    max-width: 100% !important;
    margin-top: 400px;
    padding-right: 0px !important;
  }
  .mainPage-title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 50px;
    max-width: 75%;
  }
  .mainPage-expertice-paper {
    padding-left: 10px;
    padding-right: 10px;
    margin: 20px;
  }
  .mainPage-expertice-item {
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .mainPage-expertice-papers-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
    color: black;
  }
  .mainPage-about-bg1 {
    box-shadow: 12px 9px 41px rgba(42, 124, 85, 0.3);
    border: 1px solid #0d7834;
    background-size: cover;
    background-position: unset;
    background-position-x: -10px;
    padding: 20px;
    padding-top: 70%;
    max-width: 100%;
    position: unset;
    justify-content: center;
    margin: 20px;
    padding-bottom: 50px;
  }
  .mainPage-about-logo {
    width: 80px !important;
    margin: 10px 37% 10px auto;
  }
  .mainPage-about-bg1-text-wrapper {
    max-width: 100%;
  }
  .mainPage-about-bg2-wrapper {
    margin-bottom: 55px;
  }
  .mainPage-about-bg2 {
    background-size: cover;
    background-position: unset;
    background-position-x: right;
    background-position-y: -20px;
    padding-top: 70%;
    padding-bottom: 50px;
    max-width: 100%;
    position: unset;
    margin: 20px;
  }
  .mainPage-about-vector {
    margin: 10px auto 10px 30%;
    display: block;
  }
  .mainPage-whyUs-wrapper {
    margin-bottom: 55px;
    position: relative;
  }

  .mainPage-whyUs-text {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .mainPage-whyUs-papers-text {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
  }
  .mainPage-whyUs-papers {
    height: 430px;
    padding: 0px 10px 10px 10px;
    justify-content: center;
  }
  .mainPage-FAQ-container-top {
    align-items: center;
  }
  .mainPage-FAQ-text {
    font-size: 20px;
    line-height: 22px;
    text-align: center;
  }
  .mainPage-FAQ-container {
    align-items: center;
    margin-bottom: 0px;
  }
  .mainPage-FAQ-text-exact {
    font-size: 18px;
    line-height: 20px;
  }
  .mainPage-FAQ-answer {
    font-size: 14px;
    line-height: 20px;
  }
  .mainPage-FAQ-accordion {
    padding: 0px 10px;
  }
  .mainPage-FAQ-accordion-wrapper {
    max-height: 475px;
  }
}
@media screen and (max-width: 400px) {
  .slick-prev {
    left: -10px !important;
    top: 120px !important;
    z-index: 99;
    width: 40px !important;
    height: 40px !important;
  }
  .slick-next {
    left: 91.5% !important;
    top: 120px !important;
    z-index: 99;
    width: 40px !important;
    height: 40px !important;
  }
  .slick-prev:before {
    left: 9px !important;
    top: 2.5px !important;
  }
  .slick-next:before {
    left: 13px !important;
    top: 2.5px !important;
  }
}
