.visit-card-footer-bg {
  background-image: url(../../assets/footerWawes.png);
}
.footer-address {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 32px;
  line-height: 55px;
  text-align: left;
  color: black;
}
.footer-address-text {
  font-family: 'Noto Sans';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  color: black;
  text-decoration: none;
}
.footer-address-container {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-right: 40px;
}
.footer-logo {
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin-top: 20px;
}
.footer-logo {
  display: inline-block;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 100;
  position: relative;
}
.footer-logo::before {
  content: '';
  width: 80px;
  height: 80px;
  background: url(../../assets/logo.png);
  background-size: contain;
  transition: opacity 1.5s;
  position: absolute;
  opacity: 1;
  left: 0;
  top: 0;
}
.footer-logo:after {
  content: '';
  width: 75px;
  height: 75px;
  background: url(../../assets/logoHover.png);
  background-size: contain;
  transition: opacity 1.5s;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}
.footer-logo:hover:after {
  opacity: 1;
}
.footer-logo:hover:before {
  position: absolute;
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .footer-logo {
    width: 60px;
    height: 60px;
  }
  .footer-logo::after {
    width: 60px;
    height: 60px;
  }
  .footer-logo::before {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 950px) {
  .footer-address {
    font-size: 26px;
    line-height: 35px;
  }
  .footer-address-text {
    font-size: 22px;
    line-height: 20px;
  }
}
@media screen and (max-width: 850px) {
  .footer-address {
    font-size: 22px;
    line-height: 35px;
  }
  .footer-address-text {
    font-size: 18px;
    line-height: 20px;
  }
}
@media screen and (max-width: 600px) {
  .footer-address-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 10px;
    width: 60%;
  }
  .footer-address {
    font-size: 20px;
    line-height: 35px;
  }
  .footer-address-text {
    font-size: 16px;
    line-height: 20px;
  }
  .footer-logo {
    width: 50px;
  }
}
